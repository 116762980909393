import cn from 'classnames';

import { CircleCounterProps } from './types';
import classes from './CircleCounter.module.scss';

export const CircleCounter = ({
  theme,
  counter,
  dataTestId = 'button-counter-label',
  className,
}: CircleCounterProps) => (
  <div
    className={cn(classes.counter, className, classes[theme])}
    data-testid={dataTestId}
  >
    {counter}
  </div>
);
