import { useMemo, useState, useCallback } from 'react';
import dynamic from 'next/dynamic';

import { TableHeader } from 'uikit/Table';
import useTableSettings from 'hooks/useTableSettings';
import { columnsTableSelector } from 'lib/features/tableSettings/selectors';
import { useFiles } from 'hooks/files/useFiles/useFiles';
import { useStorages } from 'hooks/storage/useStorages';
import { File } from 'hooks/files/types';
import { useAppSelector } from 'lib/hooks';
import { UploadContentBtn } from '../UploadContent/UploadContentBtn';
import { TABLE_NAME } from './helpers';
import { getColumns } from './columns';
import { TableSettings } from './TableSettings';
import { TableUi } from './TableUi';
import { EditItem } from './EditRow/types';
import { Modals, Modal } from './types';

const UploadContentModal = dynamic(() => import('../UploadContent/UploadContentModal/UploadContentModal'), { ssr: false });
const DeleteContentModal = dynamic(() => import('../DeleteContentModal/DeleteContentModal'), { ssr: false });
const FileContentModal = dynamic(() => import('../FileContentModal/FileContentModal'), { ssr: false });
const DownloadContentModal = dynamic(() => import('../DownloadContentModal/DownloadContentModal'), { ssr: false });

export const TableContent = () => {
  const { files, refetch: refetchFiles } = useFiles();
  const { data: storages, refetch: refetchStorages } = useStorages();
  const [modal, setModal] = useState<Modal>({});
  const onDelete = useCallback((value: Partial<File>) => {
    setModal({ value, modal: Modals.delete });
  }, []);

  const onEdit = useCallback((value: Partial<File>) => {
    setModal({ value, modal: Modals.edit });
  }, []);

  const onInfo = useCallback((value: Partial<File>) => {
    setModal({ value, modal: Modals.info });
  }, []);

  const onUpload = useCallback(() => {
    setModal({ modal: Modals.upload });
  }, []);

  const onClose = useCallback(() => {
    setModal({ modal: null });
  }, []);

  const onSuccessUploadOrEdit = useCallback(() => {
    refetchFiles();
    refetchStorages();
  }, [refetchFiles, refetchStorages]);

  const onSuccessDelete = useCallback(() => {
    refetchFiles();
    onClose();
  }, [refetchFiles, onClose]);

  const onDownload = useCallback(async (value: Partial<File>) => {
    setModal({ value, modal: Modals.download });
  }, []);

  const storeColumns = useAppSelector(columnsTableSelector(TABLE_NAME));

  const onSelect = useCallback((value: EditItem, original: File) => {
    switch (value) {
      case EditItem.info:
        return onInfo(original);
      case EditItem.deleteContent:
        return onDelete(original);
      case EditItem.edit:
        return onEdit(original);
      case EditItem.downloadContent:
        return onDownload(original);
      default:
        return undefined;
    }
  }, [onDelete, onInfo, onEdit, onDownload]);

  const allColumns = useMemo(() => getColumns({
    onSelect,
  }), [onSelect]);

  const columns = useTableSettings(allColumns, storeColumns);

  // todo sort to backend
  const preparedFiles = useMemo(
    () => (files || [])
      .slice()
      .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
      .map((item) => {
        const storage = (storages || []).find(({ id }) => item?.storageId === id);
        return { ...item, storage };
      }),
    [files, storages],
  );

  return (
    <>
      <TableHeader>
        <UploadContentBtn onClick={onUpload} />
        <TableSettings allColumns={allColumns} />
      </TableHeader>
      <TableUi columns={columns} files={preparedFiles} />
      <DeleteContentModal
        show={modal.modal === Modals.delete}
        fileId={modal.value?.id}
        filePath={modal.value?.path}
        onClose={onClose}
        onSuccess={onSuccessDelete}
      />
      <UploadContentModal
        fileId={modal.value?.id}
        show={[Modals.upload, Modals.edit].includes(modal.modal as Modals)}
        onClose={onClose}
        onSuccess={onSuccessUploadOrEdit}
        onCloseSuccess={onClose}
      />
      <FileContentModal
        show={modal.modal === Modals.info}
        fileId={modal.value?.id}
        onClose={onClose}
      />
      <DownloadContentModal
        show={modal.modal === Modals.download}
        fileId={modal?.value?.id}
        filePath={modal.value?.path}
        onClose={onClose}
        onSuccess={onClose}
      />
    </>
  );
};
