import { useAuth } from 'hooks/auth/useAuth';
import { useSPFiles, UseSPFiles } from './useSPFiles';
import { useSecretKeeperFiles, UseSecretKeeperFiles } from './useSecretKeeperFiles';

export type UseFiles = UseSPFiles | UseSecretKeeperFiles;

export const useFiles = (): UseFiles => {
  const { isProviderSPProvider } = useAuth();
  const spFiles = useSPFiles();
  const secretKeeperFiles = useSecretKeeperFiles();

  return isProviderSPProvider ? spFiles : secretKeeperFiles;
};