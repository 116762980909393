import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/src/components/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Login"] */ "/app/src/components/Login/Login.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/MyContent/Content/Content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Top"] */ "/app/src/components/MyContent/Top/Top.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/uikit/Box/Box.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/uikit/Spacer/Spacer.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/MyContent/MyContent.module.scss");
