import { FC } from 'react';
import dynamic from 'next/dynamic';
import { Box } from 'uikit/Box';
import { Spinner } from 'uikit/Spinner';
import { text } from './helpers';
import classes from './Entrance.module.scss';
import { EntranceProps } from './types';

const EnterMarkeplace = dynamic(() => import('../../Account/EnterMarkeplace/EnterMarkeplace'), { ssr: false });

export const Entrance: FC<EntranceProps> = ({ loading }) => {
  return (
    <Box className={classes.content} justifyContent="center" alignItems="flex-start">
      <Box className={classes.block} direction="column" justifyContent="center" alignItems="center">
        {loading ? (
          <Box alignItems="center" justifyContent="center" className={classes.spinnerWrap}>
            <Spinner />
            <span className={classes.spinnerText}>loading...</span>
          </Box>
        ) : (
          <>
            <div className={classes.text}>
              {text}
            </div>
            <EnterMarkeplace />
          </>
        )}
      </Box>
    </Box>
  );
};
