import { memo } from 'react';

import { Dropdown } from 'uikit/Dropdown';
import { EditRowProps } from './types';
import classes from './EditRow.module.scss';

export const EditRow = memo(({
  id, onSelect, items, renderToggleContent,
}: EditRowProps) => {
  return (
    <Dropdown
      items={items}
      renderToggleContent={renderToggleContent}
      classNameDropdownToggle={classes.dropdownToggle}
      classNameDropdownMenu={classes.dropdownMenu}
      onSelect={onSelect}
      dataTestId={`edit-row-${id}`}
      showArrow={false}
      id={`edit-row-${id}`}
      align="end"
    />
  );
});
