'use client';

import { useMemo } from 'react';

import cn from 'classnames';

import { Box } from 'uikit/Box';
import { Tabs } from 'uikit/Tabs';
import { useAppSelector } from 'lib/hooks';
import { isMobileSelector } from 'lib/features/windowSize';
import { Actions } from './Actions';
import { getPages } from './helpers';
import { TopProps } from './types';
import classes from './Top.module.scss';

export const Top = ({ active, className }: TopProps) => {
  const isMobile = useAppSelector(isMobileSelector);
  const pages = useMemo(() => getPages(), []);

  // const onChange = useCallback((tab: Pages) => {
  //   dispatch(trackEvent({ eventType: 'tab_open', property: { tab } }));
  // }, [dispatch]);

  return (
    <Box justifyContent="space-between" alignItems="center" className={cn(classes.wrap, className)}>
      <Tabs
        list={pages}
        active={active}
        //onClick={onChange}
        classNameItem={classes.item}
        classNameTabItem={classes.tabItem}
        classNameWrap={classes.tabsWrap}
      />
      {!isMobile && <Actions />}
    </Box>
  );
};
