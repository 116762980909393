import { Box } from 'uikit/Box';
import { Dropdown } from 'uikit/Dropdown';
import { TableConfigurator } from 'components/TableConfigurator';
import { Sorting, TableSettingsProps } from './types';
import { options } from './helpers';
import { TABLE_NAME } from '../helpers';
import classes from './TableSettings.module.scss';

export const TableSettings = ({ allColumns }: TableSettingsProps) => {
  return (
    <Box className={classes.group}>
      <Dropdown
        items={options}
        active={Sorting.newest}
        dataTestId="dropdown-sorting-content"
        align="end"
      />
      <TableConfigurator table={TABLE_NAME} columns={allColumns} />
    </Box>
  );
};
