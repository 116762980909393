import { memo } from 'react';
import { Box } from 'uikit/Box';
import { Table } from 'uikit/Table/Table';
import { TableUiProps } from './types';
import classes from './TableUi.module.scss';
import { classNames } from './helpers';

export const TableUi = memo(({ columns, files = [] }: TableUiProps) => {
  return (
    <Box className={classes.container}>
      <Table
        columns={columns as any}
        data={files}
        classNames={classNames}
        classNameContainer={classes.tableContainer}
        // onClickRow={onClickRow}
        // fetchNextPage={fetchNextPage}
        // hasNextPage={pageInfo?.hasNextPage}
        //isFetchingNextPage={isFetchingNextPage}
      />
    </Box>
  );
});

export default TableUi;