import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Box } from 'uikit/Box';
import { Item } from 'uikit/Dropdown/types';
import { getTableDate } from 'utils';
import { EditRowButton } from 'uikit/Buttons/EditRowButton';
import { getGb, round } from 'utils/math';
import { getStorageTypeTitle } from 'hooks/storage/helpers';
import { EditRow } from './EditRow';
import { AddContentBtn } from './AddContentBtn';
import { Column } from './types';
import { EditItem } from './EditRow/types';
import classes from './TableContent.module.scss';
import { getMyContentColumns } from './helpers';

const columnHelper = createColumnHelper<Column>();

export const getItems = (): Item[] => {
  return [
    {
      value: EditItem.info,
      label: 'Info',
    },
    {
      value: EditItem.edit,
      label: 'Edit',
    },
    {
      value: EditItem.createOffer,
      label: 'Create offer',
      disabled: true,
    },
    {
      value: EditItem.deleteContent,
      label: 'Delete content',
    },
    // {
    //   value: EditItem.downloadContent,
    //   label: 'Download content',
    // },
  ];
};

const renderToggleContent = () => {
  return (
    <EditRowButton />
  );
};

export const getColumns = ({
  onSelect,
}): ColumnDef<Column, any>[] => {
  const items = getItems();
  const myContentColumns = getMyContentColumns();
  return (
    [
      columnHelper.accessor('id', {
        ...myContentColumns.id,
        cell: (info) => {
          return info.getValue() || '-';
        },
      }),
      columnHelper.accessor('type', {
        ...myContentColumns.type,
        cell: (info) => {
          return info.getValue() || '-';
        },
      }),
      columnHelper.accessor('name', {
        ...myContentColumns.name,
        cell: (info) => {
          return info.getValue() || '-';
        },
      }),
      columnHelper.accessor('createdAt', {
        ...myContentColumns.createdAt,
        cell: (info) => {
          return getTableDate(info.getValue() as string);
        },
      }),
      columnHelper.accessor('size', {
        ...myContentColumns.size,
        cell: (info) => {
          const size = info.getValue();
          return size ? `${round(getGb(size as number))} GB` : '-';
        },
      }),
      columnHelper.accessor('storage', {
        ...myContentColumns.storage,
        cell: (info) => {
          const storage = info.getValue() as Column;
          return getStorageTypeTitle(storage);
        },
      }),
      columnHelper.display({
        ...myContentColumns.offerId,
        cell: () => {
          return (
            <Box justifyContent="flex-start">
              -
            </Box>
          );
        },
      }),
      columnHelper.display({
        ...myContentColumns.edit,
        cell: ({ row }) => {
          const { id } = row?.original || {};
          return (
            <Box justifyContent="flex-end">
              <Box className={classes.btnsGroup}>
                <EditRow {...{
                  id, onSelect: (value) => onSelect(value, row?.original), items, renderToggleContent,
                }}
                />
                <AddContentBtn
                  disabled
                />
              </Box>
            </Box>
          );
        },
      }),
    ]);
};