import { SquareButton } from 'uikit/Buttons/SquareButton';
import { Icon } from 'uikit/Icon';
import { AddContentBtnProps } from './types';

export const AddContentBtn = ({ disabled }: AddContentBtnProps) => {
  return (
    <SquareButton
      variant="base"
      disabled={disabled}
      data-testid="button-$-plus"
    >
      <Icon name="plus" width={14} height={14} />
    </SquareButton>
  );
};
