import { Storage } from 'lib/features/spProvider/types';
import { useAuth } from 'hooks/auth/useAuth';
import { useGetStoragesQuery } from 'lib/features/spProvider/api';

export interface UseSPStorages {
  data?: Storage[];
  loading: boolean;
  refetch: () => void;
}

export const useSPStorages = (): UseSPStorages => {
  const { isUserConnected, isProviderSPProvider } = useAuth();
  const storageResponse = useGetStoragesQuery(
    null,
    { skip: !isUserConnected || !isProviderSPProvider, refetchOnMountOrArgChange: true },
  );

  return {
    data: storageResponse?.data,
    loading: storageResponse.isLoading,
    refetch: storageResponse.refetch,
  };
};