import { useAuth } from 'hooks/auth/useAuth';
import { UseSPStorages, useSPStorages } from './useSPStorages';
import { useSecretKeeperStorages, UseSecretKeeperStorages } from './useSecretKeeperStorages';

export type UseStorages = UseSPStorages | UseSecretKeeperStorages;

export const useStorages = (): UseStorages => {
  const { isProviderSPProvider } = useAuth();
  const spStorages = useSPStorages();
  const secretKeeperStorages = useSecretKeeperStorages();
  return isProviderSPProvider ? spStorages : secretKeeperStorages;
};