import { Item } from 'uikit/Dropdown/types';
import { ReactNode } from 'react';

export enum EditItem {
  info = 'info',
  edit = 'edit',
  createOffer = 'createOffer',
  deleteContent = 'deleteContent',
  downloadContent = 'downloadContent',
}

export interface EditRowProps {
  id: string;
  onSelect: (value: EditItem) => void;
  items: Item[];
  renderToggleContent?: (item?: Item) => ReactNode;
}
