import { Item } from 'uikit/Tabs/types';
import { Pages } from 'types/pages';

export const pages = {
  [Pages.markeplace]: 'Marketplace',
  [Pages.my_content]: 'My Content',
};

export const getPageLabel = (page: Pages): string => (pages[page] || '');

export const getPages = (): Item<Pages>[] => {
  return Object.values(Pages).map((page: Pages) => ({
    label: getPageLabel(page), value: page, href: `/${page}`, disabled: page === Pages.markeplace,
  }));
};
