import { forwardRef } from 'react';
import cn from 'classnames';

import { Theme } from 'uikit/common/types';
import { ButtonWithCounterProps } from '../types';
import { Button } from '../Button';
import { CircleCounter } from '../../CircleCounter';
import classes from './ButtonWithCounter.module.scss';

export const ButtonWithCounter = forwardRef<HTMLButtonElement, ButtonWithCounterProps>(({
  children,
  counter,
  variant = 'white',
  className,
  theme = Theme.dark,
  ...props
}, ref) => {
  return (
    <div className={classes.wrapper}>
      {!!counter && (
        <CircleCounter {...{ counter, theme }} />
      )}
      <Button
        ref={ref}
        theme={theme}
        variant={variant}
        className={cn(
          classes.root,
          className,
        )}
        {...props}
      >
        {children}
      </Button>
    </div>
  );
});
