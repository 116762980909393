import { Button } from 'uikit/Buttons/Button';
import { BasketBtn } from 'components/BasketBtn';
import { Box } from 'uikit/Box';
import classes from './Actions.module.scss';

export const Actions = () => {
  return (
    <Box alignItems="center" className={classes.content}>
      <Button variant="base-link" href="/" data-testid="button-allorders" classNameLink={classes.btn} disabled>
        All orders
      </Button>
      <BasketBtn text="Deploy" data-testid="button-deploy" disabled />
    </Box>
  );
};
