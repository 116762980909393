import { File } from 'lib/features/spProvider/types';

export type Column = any;

export enum Modals {
  info = 'info',
  edit = 'edit',
  delete = 'delete',
  upload = 'upload',
  download = 'download',
}

export interface Modal {
  modal?: Modals | null;
  value?: Partial<File> | null;
}