import { memo, FC } from 'react';
import { Box } from 'uikit/Box';
import { Icon } from 'uikit/Icon';
import { ButtonWithCounter } from 'uikit/Buttons/ButtonWithCounter';
import { BasketBtnProps } from './types';
import classes from './BasketBtn.module.scss';

export const BasketBtn: FC<BasketBtnProps> = memo((props) => {
  const { text } = props;
  return (
    <ButtonWithCounter {...props}>
      <Box alignItems="center">
        <Icon name="bag" width={13} height={15} />
        <span className={classes.text}>{text}</span>
      </Box>
    </ButtonWithCounter>
  );
});

export default BasketBtn;
