'use client';

import { memo } from 'react';
import { useAuth } from 'hooks/auth/useAuth';
import { Entrance } from '../Entrance';
import { TableContent } from '../TableContent';

export const Content = memo(() => {
  const { loading, isUserConnected } = useAuth();
  return isUserConnected ? <TableContent /> : <Entrance loading={loading} />;
});

export default Content;